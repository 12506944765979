import React, { useEffect, useState } from "react";
import { Switch } from "react-router";
import { Link, NavLink } from "react-router-dom";
import Logo from "../assets/image/logo.svg";
import classes from "./Navigation.module.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";

const Navigation = () => {
  const [activeButton, setActiveButton] = useState(false);
  const [navHide, setnavHide] = useState(false);
  const [toggleNavMobile, setToggleNavMobile] = useState("");

  const toggleHamburgerButton = () => {
    setActiveButton((prevState) => !prevState);
  };
  const turnOffNav = () => {
    if (activeButton) {
      setActiveButton(false);
    }
  };
  let prevScrollpos = window.scrollY;
  const changeNavWhenScroll = () => {
    let currentScrollPos = window.scrollY;
    if (prevScrollpos > currentScrollPos) {
      setnavHide(false);
    } else {
      setnavHide(true);
    }
    prevScrollpos = currentScrollPos;
  };
  useEffect(() => {
    setToggleNavMobile(activeButton ? classes.active : "");
  }, [activeButton]);
  window.addEventListener("scroll", changeNavWhenScroll);
  const ToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <Switch>
      <nav
        className={`${classes.nav} ${
          navHide ? classes.navHide : classes.navShow
        } ${activeButton ? classes.locked : ""}`}
      >
        <div className={classes.container}>
          <Link to="/" className={`${classes.navLogo}`} onClick={ToTop}>
            <img src={Logo} alt="logo" />
          </Link>
          <div className={`${classes.MobileOnly} ${classes.navMobile}`}>
            {!activeButton ? (
              <GiHamburgerMenu
                onClick={toggleHamburgerButton}
                className={`${classes.MobileOnly} ${classes.navButton}`}
              />
            ) : (
              <AiOutlineClose
                onClick={toggleHamburgerButton}
                className={`${classes.MobileOnly} ${classes.navButton}`}
              />
            )}
          </div>
          <div className={classes.navWrapper}>
            <ul className={`${toggleNavMobile}`}>
              <li>
                <NavLink
                  to="/"
                  exact
                  onClick={turnOffNav}
                  activeClassName={classes.navlinkActive}
                >
                  HOME
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/portfolio"
                  onClick={turnOffNav}
                  activeClassName={classes.navlinkActive}
                >
                  PORTFOLIO
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contact"
                  onClick={turnOffNav}
                  activeClassName={classes.navlinkActive}
                >
                  CONTACT
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </Switch>
  );
};

export default Navigation;
