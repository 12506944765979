import { AiFillGithub, AiFillLinkedin, AiFillInstagram } from "react-icons/ai";
import classes from "./Footer.module.css";
import logo from "../assets/image/logo.svg";
import { Link } from "react-router-dom";
const Footer = () => {
  const ToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        {/* <div className={classes.logo}>
          <Link to="/" onClick={ToTop}>
            <img src={logo} alt="logo" />
          </Link>
        </div> */}
        <div>Calvin Lim © 2021</div>
        {/* <div className={classes.socials}>
          <a
            href="https://github.com/chris260602"
            target="_blank"
            rel="noreferrer"
          >
            <AiFillGithub
              className={`${classes.socialsIcon} ${classes.github}`}
            />
          </a>
          <a
            href="https://www.linkedin.com/in/christoper-lim-5459b0164/"
            target="_blank"
            rel="noreferrer"
          >
            <AiFillLinkedin
              className={`${classes.socialsIcon} ${classes.linkedin}`}
            />
          </a>
          <a
            href="https://www.instagram.com/christoperlim02/"
            target="_blank"
            rel="noreferrer"
          >
            <AiFillInstagram
              className={`${classes.socialsIcon} ${classes.instagram}`}
            />
          </a>
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;
