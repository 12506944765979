import { Fragment } from "react";
import { Redirect, Route, Switch } from "react-router";
import Footer from "./components/Footer";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Portfolio from "./pages/Portfolio";
import Navigation from "./components/Navigation";

function App() {
  return (
    <Fragment>
      <Navigation />
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/portfolio">
          <Portfolio />
        </Route>
        <Route path="*" exact>
          <Redirect to="/" />
        </Route>
      </Switch>
      <Footer />
    </Fragment>
  );
}

export default App;
