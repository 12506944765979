import React from "react";
import { useState } from "react";
import Modal from "../components/Modal";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Masonry from "react-masonry-css";

import classes from "../styles/Portfolio.module.css";
import Fade from "react-reveal/Fade";

import ComingSoon from "../assets/portfolio/comingsoon.png";
import Figma1 from "../assets/portfolio/figma1.PNG";

const Portfolio = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState({});

  const portfolioCardContent = [
    {
      id: 1,
      img: Figma1,
      label: "Personal",
      title: "UI Design",
      desc: "Create mobile design using figma",
      link: "https://www.figma.com/file/s765bqfNw8LWojO7G5xVCq/Shop-n-Go?node-id=0%3A1",
    },
    {
      id: 2,
      img: ComingSoon,
      label: "Personal",
      title: "Coming Soon!",
      desc: "Working on some cool project!",
      link: "",
    },
    {
      id: 3,
      img: ComingSoon,
      label: "Personal",
      title: "Coming Soon!",
      desc: "Working on some cool project!",
      link: "",
    },
  ];

  const UsingMobile = () => {
    let details = navigator.userAgent;

    let regexp = /android|iphone|kindle|ipad/i;

    let isMobileDevice = regexp.test(details);
    return isMobileDevice;
  };
  const openModal = (e) => {
    e.preventDefault();
    setModalVisible((prevState) => {
      if (prevState === false) {
        const ids = e.target.id;
        const target = portfolioCardContent.filter((contents) => {
          return contents.id === parseInt(ids);
        });
        setModalData({
          img: target[0].img,
          label: target[0].label,
          title: target[0].title,
          desc: target[0].desc,
          link: target[0].link,
        });
        if (!UsingMobile()) {
          document.body.style.overflow = "hidden";
          document.body.style.marginRight = "17px";
        } else {
          document.body.style.overflow = "hidden";
        }
      }
      return true;
    });
  };
  const closeModal = () => {
    if (modalVisible === true) {
      document.body.style.overflow = "unset";
      document.body.style.marginRight = "0";
    }
    setModalVisible(false);
  };

  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1,
  };
  return (
    <div>
      {modalVisible ? (
        <Modal
          title={modalData.title}
          img={modalData.img}
          desc={modalData.desc}
          label={modalData.label}
          link={modalData.link}
          manage={closeModal}
        />
      ) : (
        ""
      )}
      <div className={classes.portfolio}>
        <div className={classes.portfolioContainer}>
          <h1 className={classes.portfolioTitle}>My Portfolio</h1>
          <div className={classes.portfolioBoxContainer}>
            <Fade bottom>
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className={classes.masonrygridContainer}
                columnClassName={classes.masonrygridcolumn}
              >
                {portfolioCardContent.map((contents) => {
                  if (contents.label === "Organization") {
                    return (
                      <div
                        className={classes.portfolioBox}
                        id={"PB" + contents.id}
                      >
                        <div className={classes.portfolioImageBox}>
                          <img src={contents.img} alt={contents.title} />
                        </div>
                        <div className={classes.portfolioTitleBox}>
                          <h3 className={`${classes.label} ${classes.org}`}>
                            {contents.label}
                          </h3>
                          <h2>{contents.title}</h2>
                          <button onClick={openModal} id={contents.id}>
                            See More
                          </button>
                        </div>
                      </div>
                    );
                  } else if (contents.label === "Personal") {
                    return (
                      <div
                        className={classes.portfolioBox}
                        id={"PB" + contents.id}
                      >
                        <div className={classes.portfolioImageBox}>
                          <img src={contents.img} alt={contents.title} />
                        </div>
                        <div className={classes.portfolioTitleBox}>
                          <h3
                            className={`${classes.label} ${classes.personal}`}
                          >
                            {contents.label}
                          </h3>
                          <h2>{contents.title}</h2>
                          <button onClick={openModal} id={contents.id}>
                            See More
                          </button>
                        </div>
                      </div>
                    );
                  } else if (contents.label === "School") {
                    return (
                      <div
                        className={classes.portfolioBox}
                        id={"PB" + contents.id}
                      >
                        <div className={classes.portfolioImageBox}>
                          <img src={contents.img} alt={contents.title} />
                        </div>
                        <div className={classes.portfolioTitleBox}>
                          <h3 className={`${classes.label} ${classes.school}`}>
                            {contents.label}
                          </h3>
                          <h2>{contents.title}</h2>
                          <button onClick={openModal} id={contents.id}>
                            See More
                          </button>
                        </div>
                      </div>
                    );
                  } else if (contents.label === "Work") {
                    return (
                      <div
                        className={classes.portfolioBox}
                        id={"PB" + contents.id}
                      >
                        <div className={classes.portfolioImageBox}>
                          <img src={contents.img} alt={contents.title} />
                        </div>
                        <div className={classes.portfolioTitleBox}>
                          <h3 className={`${classes.label} ${classes.work}`}>
                            {contents.label}
                          </h3>
                          <h2>{contents.title}</h2>
                          <button onClick={openModal} id={contents.id}>
                            See More
                          </button>
                        </div>
                      </div>
                    );
                  }
                })}
              </Masonry>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Portfolio;
