import React, { useState } from "react";
import ReactDOM from "react-dom";
import classes from "./Modal.module.css";
import { AiOutlineClose } from "react-icons/ai";
const tesModal = document.getElementById("modal");
const Modal = (props) => {
  const [closeModal, setCloseModal] = useState(false);
  let labelTitle =
    props.label === "Personal" ? (
      <h3 className={`${classes.personal} ${classes.label}`}>{props.label}</h3>
    ) : props.label === "Organization" ? (
      <h3 className={`${classes.org} ${classes.label}`}>{props.label}</h3>
    ) : props.label === "Work" ? (
      <h3 className={`${classes.work} ${classes.label}`}>{props.label}</h3>
    ) : props.label === "School" ? (
      <h3 className={`${classes.school} ${classes.label}`}>{props.label}</h3>
    ) : (
      <h3>{props.label}</h3>
    );
  const CloseModal = () => {
    setCloseModal(true);
    setTimeout(props.manage, 300);
  };
  return ReactDOM.createPortal(
    <React.Fragment>
      <div className={classes.ModalWrapper}>
        <div
          className={`${classes.backdrop} ${closeModal ? classes.closing : ""}`}
          onClick={CloseModal}
        ></div>
        <div
          className={`${classes.modal} ${closeModal ? classes.closing : ""} `}
        >
          <AiOutlineClose className={classes.close} onClick={CloseModal} />
          <div className={classes.modalContainer}>
            <img src={props.img} alt={props.title} />
            <div className={classes.modalDescContainer}>
              {labelTitle}
              <h1>{props.title}</h1>
              <p>{props.desc}</p>
              {!props.link ? (
                ""
              ) : (
                <a href={props.link} target="_blank" rel="noreferrer">
                  More Info
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>,
    tesModal
  );
};

export default Modal;
