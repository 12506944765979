import classes from "../styles/Contact.module.css";
import Fade from "react-reveal/Fade";
import {
  AiFillGithub,
  AiFillInstagram,
  AiFillLinkedin,
  AiOutlineWhatsApp,
} from "react-icons/ai";

const Contact = () => {
  return (
    <main className={classes.mainContact}>
      <div className={classes.container}>
        <div className={classes.contact}>
          <div className={classes.contactTitle}>
            <h1>Socials</h1>
            <p>Here are my socials</p>
          </div>
          <div className={classes.contactSocialWrapper}>
            <Fade bottom>
              <div className={classes.socials}>
                <div className={classes.socialContainer}>
                  <h2>Whatsapp</h2>
                  <a
                    href="https://api.whatsapp.com/send?phone=082365569988"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <AiOutlineWhatsApp
                      className={`${classes.socialsIcon} ${classes.whatsapp}`}
                    />
                  </a>
                </div>
                <div className={classes.socialContainer}>
                  <h2>LinkedIn</h2>
                  <a
                    href="https://www.linkedin.com/in/calvin-lim-a87228238/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <AiFillLinkedin
                      className={`${classes.socialsIcon} ${classes.linkedin}`}
                    />
                  </a>
                </div>
                <div className={classes.socialContainer}>
                  <h2>Instagram</h2>
                  <a
                    href="https://www.instagram.com/calvinbarbar/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <AiFillInstagram
                      className={`${classes.socialsIcon} ${classes.instagram}`}
                    />
                  </a>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </main>
  );
};
export default Contact;
