import { Link } from "react-router-dom";
import classes from "../styles/Home.module.css";
import { useRef } from "react";
import { HiArrowNarrowDown } from "react-icons/hi";
import laptop from "../assets/image/laptop.svg";
import Typewriter from "typewriter-effect";
import Slide from "react-reveal/Slide";
import Bounce from "react-reveal/Bounce";
import Fade from "react-reveal/Fade";
import muka from "../assets/image/muka.jpeg";
const Home = () => {
  const seeMoreBtnRef = useRef(null);
  const seeMoreScroll = () => {
    seeMoreBtnRef.current.scrollIntoView();
  };
  return (
    <main className={classes.main}>
      <div className={classes.landingSection}>
        <div className={classes.container}>
          <div className={classes.landingName}>
            <h1>Calvin Lim</h1>
          </div>
          <div className={classes.landingDesc}>
            <Typewriter
              options={{
                strings: [
                  "Studying in School of Computer Science from BINUS University",
                ],
                autoStart: true,
                loop: true,
                delay: 65,
                deleteSpeed: 4,
                pauseFor: 3000,
              }}
            />
          </div>
          <div className={classes.cv}>
            <a href={"/CalvinLimResume.pdf"} target="_blank" rel="noreferrer">
              See My CV
            </a>
          </div>

          <div className={classes.landingButton}>
            <button onClick={seeMoreScroll} className={classes.seeMoreBtn}>
              <HiArrowNarrowDown className={classes.arrow} />
            </button>
          </div>
        </div>
      </div>

      <div className={classes.story} ref={seeMoreBtnRef}>
        <div className={classes.container}>
          <div className={classes.content}>
            <Bounce left>
              <div>
                <h1>About Me</h1>
              </div>
              <div className={classes.pwrap}>
                <p>
                  I am a curious person that wants to explore new stuff. I am
                  easily adaptable to new environment. I want to focus on new
                  things that I want to learn. I like to play musical
                  instruments like guitar and drum, I also like to socialize
                  with new people and build connections with them.
                </p>
              </div>
            </Bounce>
          </div>
          <Slide bottom>
            <div className={classes.contentImg}>
              <img src={muka} className={classes.cal} alt="Story" />
            </div>
          </Slide>
        </div>
      </div>

      <div className={classes.development}>
        <div className={classes.container}>
          <Fade left>
            <div className={classes.contentImg}>
              <img src={laptop} alt="dev" />
            </div>
          </Fade>
          <div className={classes.content}>
            <Fade right>
              <div>
                <h1>Development</h1>
              </div>
              <div>
                <p>Here are some of my work and achievement.</p>
                <div className={classes.buttonWrapper}>
                  <Link to="/portfolio" className={classes.button}>
                    See my Work
                  </Link>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </main>
  );
};
export default Home;
